<template>
<!--  <img alt="Vue logo" src="./assets/loaf.jpg">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  <main/>-->
  <div class="content"/>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Main from "@/components/Main";

export default {
  name: 'App',
  // components: {
  //   // HelloWorld,
  //   Main
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-image: url("./assets/loaf.jpg");
}
html {
  background-image: url("./assets/loaf.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  background-color: black;
}
</style>
